<template>
  <v-container fluid>
    <v-alert type="error" v-if="error">{{ error }}</v-alert>
    <v-card
        v-else
        flat
    >
      <v-dialog v-model="showCreateNewNutrient" max-width="600">
        <v-card>
          <v-card-title>Create a new nutrient type</v-card-title>
          <v-card-text>
            This allows you to create a new type of nutrient value to be associated with an ingredient.
            You should only use this if a new nutrient definition is required. Do not use it to enter
            nutrient values! If in doubt, please ask before using this.

            <v-form>
              <v-text-field v-model="nutrient.nutrientname" label="Nutrient Name (eg, PROTEIN)"/>
              <v-text-field v-model="nutrient.nutrientnamef" label="Nutrient Name (French)"/>
              <v-text-field v-model="nutrient.nutrientsymbol" label="Nutrient Symbol (eg, PROT)"/>
              <v-text-field v-model="nutrient.tagname" label="Tag Name (eg, PROCNT)"/>
              <v-text-field v-model="nutrient.unit" label="Unit (eg, g, kg, ml)"/>
              <v-text-field v-model="nutrient.nutrientdecimals" min=0 type="number" label="decimal places to show"/>
              <!--              <v-text-field v-model="nutrient.code" label="Nutrient Code (number)" type="number"/>-->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="showCreateNewNutrient=false;">Cancel</v-btn>
            <v-spacer/>
            <v-btn class="primary" @click="createNutrient(nutrient)">Create</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <v-card-title>
        Ingredient Nutrient Analysis
        <v-spacer/>
        <v-btn
            v-if="!readOnly"
            v-model="editMode"
            active-class="warning"
            @click="editMode=!editMode">
          {{ editMode ? 'Cancel Edit' : 'Edit' }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col>
            <IngredientAutocomplete
                :click-outside-close="false"
                dense
                v-bind:ingredient.sync="ingredient"
            />
            <!--            v-on:close="ingredient && ingredient.foodid && displayIngredient(ingredient,newIngredientAmount)"-->
          </v-col>
          <v-col cols="2">
            <v-text-field
                dense
                type="number"
                v-model="newIngredientAmount"
                reverse
                prefix="g"
            />
          </v-col>
          <v-col cols="1">
            <v-btn :disabled="!(ingredient && ingredient.foodid)" small fab icon color="success"
                   @click="displayIngredient(ingredient,newIngredientAmount)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-alert v-if="nutrientTable.length===0" type="info">
          Select an ingredient and hit the green plus button to
          show the nutrient values for {{ newIngredientAmount }}g of that ingredient.
        </v-alert>

        <v-row dense v-for="(amount,foodid) of ingredientAmounts" v-bind:key="foodid" class="">
          <v-col class="">
            <v-toolbar flat dense>
              <h2 class="mr-4">{{ foodid }} - {{ ingredientNameMap[foodid] }}</h2>
              <template v-if="editMode">
                <v-btn class="mr-4" v-if="!isModified[foodid]" @click="clone(foodid)">Clone</v-btn>
                <v-btn class="mr-4" v-if="isModified[foodid]" @click="saveEdits(foodid)">Save</v-btn>
                <v-btn class="mr-4" v-if="isModified[foodid]" @click="cancelEdits(foodid)">Cancel</v-btn>
              </template>
            </v-toolbar>

          </v-col>
          <v-col class="" cols="1">
            <v-text-field
                single-line
                dense
                type="number"
                v-model="ingredientAmounts[foodid]"
                reverse
                prefix=" g"
            >
              <slot name="">

              </slot>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn small
                   @click="removeIngredient(foodid)"
                   color="red" icon fab>
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-text v-if="nutrientTable.length>0">
        <v-toolbar flat>
          <v-text-field
              v-model="searchNutrientName"
              label="Search"
          />
          <v-spacer/>
          <v-checkbox dense
                      v-model="showTargetsOnly"
                      label="Included in Targets"
          />
          <v-checkbox
              dense
              v-model="showZeroValues"
              label="Show Zero Values"
          />
          <template v-if="editMode">
            <v-spacer/>
            <v-btn text outlined @click="showCreateNewNutrient=true">Create New</v-btn>
          </template>
        </v-toolbar>
        <v-row>
          <v-col>
            <v-data-table
                :search="searchNutrientName"
                :items="nutrientTable"
                :headers="nutrientTableHeaders"
                disable-pagination
                hide-default-footer
                dense
                sort-by="id"
            >
              <template v-slot:item="{headers,item}">
                <tr>
                  <td v-for="(h,i) of headers" v-bind:key="i">
                    <template v-if="editMode && h.canEdit">
                      <v-text-field
                          class="mt-2 mb-0"
                          min="0"
                          dense
                          single-line
                          v-model="item[h.value]"
                          type="number"
                          reverse
                          @change="setChanged(item,h.value)"
                          @input="setChanged(item,h.value)"
                          :outlined="isValueChanged(item, h.value)"
                          :background-color="isValueChanged(item, h.value) ? '#FFEBEE' : ''"
                          :append-outer-icon="isValueChanged(item, h.value) ? 'mdi-undo' : ''"
                          @click:append-outer="revertChange(item,h.value)"
                      />
                      <!--                      {{ h.value }} {{ item.nutrientnameid }} {{ changed[h.value] }}-->
                      <!--                      {{ changed[h.value] }}-->
                    </template>
                    <template v-if="!editMode || !h.canEdit">{{ item[h.value] }}</template>
                  </td>
                </tr>
                <!--                <tr>-->
                <!--                  <pre>-->
                <!--                    {{ item }}-->
                <!--                    {{ changed }}-->
                <!--                </pre>-->
                <!--                </tr>-->
                <!--                <pre>{{ props }}</pre>-->
              </template>
              <!--              <template v-slot:item.nutrientname="props">-->
              <!--                <v-edit-dialog-->
              <!--                    :return-value.sync="props.item.name"-->

              <!--                >-->
              <!--&lt;!&ndash;                  @save="alert"&ndash;&gt;-->
              <!--&lt;!&ndash;                  @cancel="cancel"&ndash;&gt;-->
              <!--&lt;!&ndash;                  @open="open"&ndash;&gt;-->
              <!--&lt;!&ndash;                  @close="close"&ndash;&gt;-->
              <!--                  {{ props.item.name }}-->
              <!--                  <template v-slot:input>-->
              <!--                    <v-text-field-->
              <!--                        v-model="props.item.name"-->
              <!--                        label="Edit"-->
              <!--                        single-line-->
              <!--                        counter-->
              <!--                    ></v-text-field>-->
              <!--                  </template>-->
              <!--                </v-edit-dialog>-->
              <!--              </template>-->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import {mapActions, mapGetters} from 'vuex';
import IngredientAutocomplete from '@/components/IngredientAutocomplete';
// import NutrientTable from '@/views/NutrientTable';

export default {
  name: "MealNutrientsMulti",
  components: {IngredientAutocomplete},
  // components: {NutrientTable},
  data() {
    return {
      error: false,
      nutrients: false,
      meals: [],
      headers: [
        {text: 'ID', value: 'nutrientnameid', width: 70},
        {text: 'Name', value: 'nutrientname',},
        {value: 'nutrientvalue', text: 'Amount', width: 100, align: 'right'},
        {value: 'unit', text: 'Unit', width: 100, align: 'left'},
        // {value: 'problem', text: 'Problem'},
        // {value: 'adjustment', text: 'Off by'}
        // {value: 'target'}
      ],
      headersMinimal: [
        // {text: 'ID', value: 'nutrientnameid', width: 70},
        // {text: 'Name', value: 'nutrientname',},
        {value: 'nutrientvalue', text: 'Amount', width: 100, align: 'right'},
        {value: 'unit', text: 'Unit', width: 100, align: 'left'},
        // {value: 'problem', text: 'Problem'},
        // {value: 'adjustment', text: 'Off by'}
        // {value: 'target'}
      ],
      ingredients: [],
      ingredientAmounts: {},
      ingredient: null,
      newIngredientAmount: 100,
      editMode: false,
      changed: {},
      isModified: {},
      showZeroValues: false,
      searchNutrientName: null,
      showCreateNewNutrient: false,
      nutrient: {},
      targets: null,
      showTargetsOnly: true
    }
  },
  mounted() {
    this.ingredientAmounts = this.$route.query;
    this.fetchData();
  },
  watch: {
    '$route.query'(val) {
      this.ingredientAmounts = val;
      this.fetchData();
    },
  },
  computed: {
    readOnly() {
      return !!this.$route.meta.readOnly;
    },
    ...mapGetters(['getDietName']),
    ingredientsWithNutrientAmount() {
      console.log('updating nutes')
      return this.ingredients.map(ingredient => ({
        ...ingredient,
        nutrients: ingredient.nutrients.map(n => ({
          ...n,
          nutrientvalue: (n.nutrientvalue * (this.ingredientAmounts[ingredient.foodid] / 100)).toFixed(n.nutrientdecimals)
        }))
      }));
    },
    ingredientNameMap() {
      const nameMap = {};
      this.ingredients.forEach(i => nameMap[i.foodid] = i.fooddescriptionsimple);
      return nameMap;
    },
    nutrientTableHeaders() {
      const valueColumns = this.ingredients.length === 1
          ? [{
            value: `${this.ingredients[0].foodid}`, text: 'Amount',
            // width: 100,
            align: 'right'
          }]
          : [{
            value: `total`,
            text: 'Total',
            // width: 100,
            align: 'right',
            isTotal: true
          },
            ...this.ingredients.map(i => ({
              value: `${i.foodid}`,
              text: i.fooddescriptionsimple,
              // width: 100,
              align: 'right'
            }))];

      valueColumns.forEach(c => {
        if (!c.isTotal) c.canEdit = true
      });
      const headers = [
        {text: 'ID', value: 'nutrientnameid', width: 70},
        {text: 'Name', value: 'nutrientname',},
        {text: 'Symbol', value: 'nutrientsymbol',}
        ,
        ...valueColumns,
        {value: 'unit', text: 'Unit', width: 100, align: 'left'}
      ];
      // console.log('headers', headers)
      return headers;
    },
    nutrientTable() {
      //console.log('nutrientTable')
      const nutrientAmountMap = {};
      const nutrientDecimals = {};

      function setNutrientAmount(n, ingredientAmount) {
        const id = n.nutrientnameid;
        nutrientAmountMap[id] = nutrientAmountMap[id] || {
          nutrientnameid: id,
          ...n,
          originalValues: {}
        };
        nutrientAmountMap[id][n.foodid] = (n.nutrientvalue * (ingredientAmount / 100)).toFixed(n.nutrientdecimals);
        nutrientAmountMap[id].originalValues[n.foodid] = nutrientAmountMap[id][n.foodid];
        nutrientDecimals[id] = n.nutrientdecimals;
      }

      this.ingredients.forEach(ingredient => {
        ingredient.nutrients.forEach(n => {

          if (this.showTargetsOnly) {
            if (!this.nutrientIdsWithTargets.has(n.nutrientnameid)) {
              // skip
              return;
            }
          }
          const ingredientAmount = this.ingredientAmounts[ingredient.foodid];
          setNutrientAmount(n, ingredientAmount);
        });
      });
      if (this.showZeroValues) {
        this.nutrients.forEach(n => {

          if (!nutrientAmountMap[n.nutrientnameid]) {
            if (this.showTargetsOnly) {
              if (!this.nutrientIdsWithTargets.has(n.nutrientnameid)) {
                // skip
                return;
              }
            }
            setNutrientAmount(n, 0);
          }
        })
      }

      const rows = Object.values(nutrientAmountMap);
      rows.forEach(row => {
        const decimals = nutrientDecimals[row.nutrientnameid];
        row.total = this.ingredients.map(({foodid}) => parseFloat(row[foodid] || 0)).reduce((a, i) => a + i, 0).toFixed(decimals);
      })
      // console.log('nutrienttable', rows);
      return rows;
    },
    nutrientIdsWithTargets() {
      console.log('this.targets', this.targets);
      return this.targets && new Set(this.targets.map(t => t.nutrient_id));
    }
  },
  methods: {
    ...mapActions(['fetchDiets']),
    async fetchData() {
      const ingredientIds = Object.keys(this.ingredientAmounts);
      Object.keys(this.ingredientAmounts).forEach(i =>
          this.ingredientAmounts[i] = parseFloat(parseFloat(this.ingredientAmounts[i]).toFixed(2))  //.replace(/0+$/,'')
      )
      if (ingredientIds.length === 0) {
        // this.error = 'No ingredients selected';
        this.ingredients = [];
        return;
      }
      const fetchIngredientsPromise = Promise
          .all(ingredientIds
              .map(id =>
                  api.get(`v2/food/${id}`)
                      .then(r => r.data)
                      .catch(e => ({foodid: id, error: e}))
              )
          )
          .then(ingredients => {
            console.log('ingredients', ingredients);
            return Promise.all(
                ingredients
                    .map(ingredient =>
                        ingredient.error
                            ? ({...ingredient, nutrients: []})
                            : api.get(`v2/food/${ingredient.foodid}/nutrients`)
                                .then(r => ({
                                  ...ingredient,
                                  nutrients: r.data,
                                }))
                    )
            )
          })
          .then(ingredientsWithNutrientInfo => {
            this.ingredients = ingredientsWithNutrientInfo;
            this.ingredients.forEach(i => {
              this.$set(this.changed, i.foodid, {});
              this.$set(this.isModified, i.foodid, false);
            });

            console.log('ingredients', this.ingredients);
          });

      return Promise.all([fetchIngredientsPromise,
        this.nutrients || api.get(`nutrients`)
            .then(result => {
              console.log('get nutrients', result.data);
              this.nutrients = result.data;
            }),
        this.targets || api.get(`nutrients/targets`)
            .then(result => {
              console.log('get targets', result.data);
              this.targets = result.data;
            })
      ]);
      // .then(() => this.fetchDiets());
    },
    getAdjustmentRequired: function (item) {
      const {amount} = item;
      const {lower_limit, upper_limit, underTarget} = item.problem || {};
      let adjustmentNeeded = 0;
      if (lower_limit) {
        adjustmentNeeded = Math.max(adjustmentNeeded, lower_limit - amount);
      }
      if (upper_limit) {
        adjustmentNeeded = Math.max(adjustmentNeeded, amount - upper_limit);
      }
      if (underTarget) {
        adjustmentNeeded = Math.max(adjustmentNeeded, underTarget - amount);
      }
      return adjustmentNeeded;
    },
    displayIngredient(ingredient, amount) {
      if (!(ingredient && ingredient.foodid)) {
        console.error('tried to add ingredient with no id', ingredient);
        return;
      }
      console.log('current reoute', this.$route);
      const query = JSON.parse(JSON.stringify(this.$route.query || {}));
      console.log('add to display', {query, ingredient});
      amount = amount || Object.values(query).pop() || 100;
      query[ingredient.foodid] = amount;
      console.log('updating query', query);
      return this.$router.replace({...this.$route, query});
    },
    removeIngredient(foodid) {
      const query = JSON.parse(JSON.stringify(this.$route.query || {}));
      delete query[foodid];
      return this.$router.replace({...this.$route, query});
    },
    saveEdits(foodid) {
      const update = JSON.parse(JSON.stringify(this.changed[foodid]));
      for (const key of Object.keys(update)) {
        const nutrientvalue = Number(update[key]);
        // normalise to /100
        update[key] = nutrientvalue / (this.ingredientAmounts[foodid] / 100);
      }

      console.log('saving edits for ', foodid, update);
      api.post(`/v2/food/${foodid}/nutrients`, update)
          .then(r => {
            const ingredient = this.ingredients.find(i => i.foodid == foodid);
            console.log('updating', {ingredient, nutrients: r.data})
            ingredient.nutrients = r.data;
            this.isModified[foodid] = false;
            this.changed[foodid] = {};
          })
          .catch(e => alert('failed! ' + JSON.stringify(e)));
    },
    cancelEdits(foodid) {
      this.isModified[foodid] = false;
      this.changed[foodid] = {};
      return this.fetchData();
    },
    hasChanges(foodid) {
      return this.isModified[foodid];
    },
    setChanged(item, foodid) {
      const newValue = item[foodid];
      // console.log('set changed', {newValue, oldValue: item.originalValues[foodid]});
      const isChanged = this.isValueChanged(item, foodid);
      if (isChanged) {
        this.$set(this.changed[foodid], item.nutrientnameid, newValue);
        this.isModified[foodid] = true;
      } else {
        this.revertChange(item, foodid);
      }
    },
    revertChange(item, foodid) {
      this.$set(this.changed[foodid], item.nutrientnameid, undefined);
      delete this.changed[foodid][item.nutrientnameid];
      item[foodid] = item.originalValues[foodid];
      this.isModified[foodid] = Object.keys(this.changed[foodid]).length > 0;
    },
    isValueChanged(item, foodid) {
      return item[foodid] && Number(item.originalValues[foodid]) !== Number(item[foodid]);
    },
    clone(foodid) {
      const newName = prompt('Enter a new food name for the new copy of ' + this.ingredientNameMap[foodid]);
      if (newName) {
        api.post(`v2/food/${foodid}/clone`, {name: newName})
            .then(r => {
              console.log('result', r);
              const query = JSON.parse(JSON.stringify(this.$route.query));
              const clone = r.data;
              query[clone.foodid] = this.ingredientAmounts[foodid];
              console.log('updated query', {query})
              this.$router.push({query})
            })
            .catch(e => console.error('error', e));
      }
    },
    createNutrient(nutrient) {
      console.log('creating...', nutrient);
      nutrient.nutrientdecimals = Number(nutrient.nutrientdecimals);
      return api.post('nutrients', nutrient)
          .then(() => {
            this.showCreateNewNutrient = false;
            this.nutrients = null;
            return this.fetchData();
          });
    }
  }
}
</script>

<style scoped>


>>> .nutrient_problem-1 {
  background-color: #EF5350;
  font-weight: bolder;
}

>>> .nutrient_problem-2 {
  background-color: #E57373;
  font-weight: bolder;
}

>>> .nutrient_problem-3 {
  background-color: #EF9A9A;
  font-weight: bolder;
}

>>> .nutrient_problem-4 {
  background-color: #FFCDD2;
  font-weight: bolder;
}

>>> .nutrient_problem-5 {
  background-color: #FFEBEE;
  font-weight: bolder;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

</style>
